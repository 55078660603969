<template>
  <Header :isLogout="true" />
  <div class="mt-4 h-screen pb-16 overflow-auto">
    <el-input v-model="filterText" placeholder="Filter item" class="rounded-none" :input-style="{
      'border-radius': 'none !important'
    }" />

    <el-tree class="filter-tree" ref="treeRef" :data="mergeTagItems" :props="defaultProps"
      :filter-node-method="filterNode" @node-click="handleClick" />
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import axios from 'axios'
import Header from '../components/Header'

const defaultProps = {
  children: 'menu',
  label: 'title',
}

const treeRef = ref()
const mergeTagItems = ref([])

const filterText = ref('')
const filterNode = (value, data) => {
  if (!value) return true
  const lowerCaseTitle = data.title.toLowerCase()
  const lowerCaseValue = value.toLowerCase()

  return lowerCaseTitle.includes(lowerCaseValue)
}

watch(filterText, (val) => {
  treeRef.value.filter(val)
})

onMounted(async () => {
  await axios.get("https://testapi.avr.best/api/v1/merge-tags").then((response) => {
    mergeTagItems.value = [
      {
        title: "Merge Tags",
        menu: response.data.merge_tags.Variables
      },
      {
        title: "Conditions",
        menu: response.data.conditions.TinyMceConditions
      },
      {
        title: "Loops",
        menu: response.data.loops.Loops
      },
      {
        title: "Loop Elements",
        menu: response.data.loop_elements.Loops
      },
      {
        title: '@else',
        value: '@else'
      },
      {
        title: '@endif',
        value: '@endif'
      },
      {
        title: '@endforeach',
        value: '@endforeach'
      }
    ]
  })
})

const handleClick = (item) => {
  if (item.value) {
    return window.Word.run(async (context) => {
      const range = context.document.getSelection()
      const myContentControl = range.insertContentControl()
      myContentControl.tag = "Tag"
      myContentControl.title = item.title
      myContentControl.insertText(item.value, "replace")
      myContentControl.cannotEdit = true

      return await context.sync()

    }).catch(function (error) {
      if (error instanceof window.OfficeExtension.Error) {
        console.log('Error code and message: ' + error.toString())
      }
    })
  }
}

</script>

<style>
.el-input__inner {
  border-radius: 0px !important;
}
</style>
